import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ClientForm } from '../../../../models/ClientForm';
import ClientFormService from '../../../../services/ClientFormService';
import { FCWithChildren } from '../../../../types/FCWithChildren';
import { dataAttributeProps } from '../../../../utils/ComponentUtils';
import LanguageUtils from '../../../../utils/LanguageUtils';
import { ChevronIcon, ChevronType } from '../../../shared/icon/ChevronIcon';
import Loader from '../../../shared/Loader';
import { ParseKeys } from 'i18next';
import { FormType } from '../../../../models/FormTypes';

type CollapsedRecordProps = {
  recordId: string;
  templateFormId: string;
};

const CollapsedRecord: FCWithChildren<CollapsedRecordProps> = (props) => {
  const { recordId, templateFormId } = props;
  const [record, setRecord] = useState<ClientForm | null>(null);
  const { t } = useTranslation('common');

  const [isLoading, setIsLoading] = useState(true);
  const [failedMessage, setFailedMessage] = useState<ParseKeys<'common'>>('collapsed-record.error.unkown');

  useEffect(
    function getRecord() {
      setIsLoading(true);
      ClientFormService.getForm(recordId, [404, 403])
        .then((res) => {
          setRecord(res.data);
        })
        .catch((err) => {
          if (err?.meta?.code === 403 || err.status === 403) {
            setFailedMessage('collapsed-record.error.permissions');
          } else if (err?.meta?.code === 404 || err.status === 404) {
            setFailedMessage('collapsed-record.error.notfound');
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [recordId],
  );

  const link = useMemo(() => {
    if (!record) return '';

    if (record.type === FormType.Resource) {
      return `/clients/${record.clientId}/resources/${templateFormId}/${recordId}`;
    }

    return `/clients/${record.clientId}/forms/${record.id}`;
  }, [record, recordId, templateFormId]);

  return (
    <div {...dataAttributeProps(props)} className={`border-grey-5 relative my-2 flex w-full flex-col rounded border-2 bg-white`}>
      {isLoading && (
        <div className="relative h-full min-h-14 w-full">
          <Loader size={12} />
        </div>
      )}
      {!isLoading && (
        <Link className={`flex items-center justify-between px-4 py-3 ${!!record && 'cursor-pointer'}`} to={link}>
          {!!record && (
            <>
              <div className="text-dpm-14" data-cy="form-title">
                <div className="text-color-2 font-medium">{record.subtitle || LanguageUtils.getTranslation('title', record.form.translations)}</div>
                {record.subtitle && <div className="text-color-3 text-dpm-12">{LanguageUtils.getTranslation('title', record.form.translations)}</div>}
              </div>
              <div className="flex items-center justify-end">
                <ChevronIcon type={ChevronType.RIGHT} className="h-6 w-6 justify-end" />
              </div>
            </>
          )}
          {!record && !isLoading && (
            <div className="text-dpm-14 w-full text-center">
              <div className="text-color-2 font-medium">{t('collapsed-record.error.title')}</div>
              <div className="text-color-3 text-dpm-12">{t(failedMessage) as string}</div>
            </div>
          )}
        </Link>
      )}
    </div>
  );
};

export default CollapsedRecord;
