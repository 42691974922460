import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useFormAction } from '../../../../contexts/FormActionContext';
import { useFormRendererInfo } from '../../../../contexts/FormRendererContext';
import { ToastType, useToasts } from '../../../../contexts/ToastContext';
import { EventSystem } from '../../../../events/EventSystem';
import ResourcePicklistUpdatedEvent from '../../../../events/ResourcePicklistUpdatedEvent';
import useResourcePermissions from '../../../../hooks/permissions/useResourcePermissions';
import { FormConfig, FormListItem } from '../../../../models/Form';
import { FormType } from '../../../../models/FormTypes';
import { currentClientAtom } from '../../../../recoil/atoms/Clients';
import ClientFormService from '../../../../services/ClientFormService';
import ClientTemplateFormService from '../../../../services/ClientTemplateFormService';
import TemplateFormService from '../../../../services/TemplateFormService';
import FormUtils from '../../../../utils/FormUtils';
import { Option } from '../../../Option';
import { CollapsedForm, CollapsedFormType } from '../../../shared/collapsed-form/CollapsedForm';
import Group from '../../../shared/group/Group';
import Loader from '../../../shared/Loader';
import PickListModal from '../../../shared/pick-list/PickListModal';
import ActionBaseProps from '../ActionBaseProps';
import ActionTitleDescription from '../ActionTitleDescription';
import CollapsedRecord from './CollapsedRecord';
import { SelectionMode } from './SelectionModes';
import LanguageUtils from '../../../../utils/LanguageUtils';

export type ResourcePicklistActionData = {
  lockedTemplateId?: string;
  buttonText: string;
  selectionMode?: SelectionMode;
};

type ResourcePicklistActionProps = ActionBaseProps<Option<string, string>[], ResourcePicklistActionData>;

const ResourcePicklistAction: FC<ResourcePicklistActionProps> = (props) => {
  const { response, data, required } = props;
  const { lockedTemplateId, buttonText, question, selectionMode = SelectionMode.Mutltiple } = data;
  const { clientFormId } = useFormRendererInfo();
  const { onAnswer, readOnly, currentAction } = useFormAction(props);

  const { t, i18n } = useTranslation(['activity-type', 'form-builder']);
  const toasts = useRef(useToasts());

  const [selectTemplateOpen, setSelectTemplateOpen] = useState(false);
  const [selectFormOpen, setSelectFormOpen] = useState(false);

  const [templatesLoading, setTemplatesLoading] = useState(false);
  const [templateLoading, setTemplateLoading] = useState(true);
  const [templates, setTemplates] = useState<FormConfig[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<Option<string, string>>();
  const [selectedTemplateForm, setSelectedTemplateForm] = useState<FormConfig | null>(null);
  const [setupError, setSetupError] = useState(false);
  const [isLinkedToClient, setIsLinkedToClient] = useState(false);

  const [formsLoading, setFormsLoading] = useState(false);
  const [forms, setForms] = useState<FormListItem[]>([]);
  const currentClient = useRecoilValue(currentClientAtom);

  const { canMaintainRecords } = useResourcePermissions(selectedTemplateForm);

  const openTemplatePicker = () => {
    if (readOnly) {
      return;
    }

    if (lockedTemplateId) {
      setSelectFormOpen(true);
    } else {
      setSelectTemplateOpen(true);
    }
  };

  const templateService = useMemo(() => {
    if (currentClient) {
      return new ClientTemplateFormService(currentClient.id);
    }
    return TemplateFormService;
  }, [currentClient]);

  // Get all templates initially
  useEffect(() => {
    if (!currentClient?.id) {
      return;
    }

    // defer loading untill we open the template modal
    if (templates.length !== 0 || !selectTemplateOpen) {
      return;
    }

    setTemplatesLoading(true);
    templateService.getAllUsedTemplates({ latestOnly: true, includeArchived: true, types: [FormType.Asset] }).then((res) => {
      setTemplates(res.data);
      setTemplatesLoading(false);
    });
  }, [currentClient, lockedTemplateId, selectTemplateOpen, selectedTemplate, templateService, templates.length]);

  // Get the locked template
  useEffect(() => {
    if (!lockedTemplateId) {
      setTemplateLoading(false);
      return;
    }

    templateService.isLinkedToClient(lockedTemplateId).then((isLinkedRes) => {
      setIsLinkedToClient(isLinkedRes.data);
      setTemplateLoading(true);
      templateService.getFormTemplate(lockedTemplateId).then((res) => {
        setSelectedTemplate({ id: res.data.id, text: FormUtils.getFormTitle({ form: res.data }), value: res.data.id });
        setSelectedTemplateForm(res.data);
        setTemplateLoading(false);
      });
    });
  }, [lockedTemplateId, templateService]);

  const fetchForms = useCallback(() => {
    setForms([]);

    if (!selectedTemplate || !currentClient) {
      setSetupError(true);
      return Promise.resolve();
    }

    setFormsLoading(true);
    return ClientFormService.getForms(currentClient.id, {
      templateIds: [selectedTemplate.id],
      isArchived: false,
    }).then((res) => {
      setForms(res.data.filter((x) => x.id !== clientFormId));
      setFormsLoading(false);
    });
  }, [currentClient, clientFormId, selectedTemplate]);

  // Get client-forms of the selected template type
  useEffect(() => {
    if (!selectFormOpen) {
      return;
    }

    fetchForms();
  }, [selectedTemplate, fetchForms, selectFormOpen]);

  const templateSelected = (items: Option<string, string>[]) => {
    setSelectedTemplate(items[0]);
    if (items.length) {
      setSelectedTemplateForm(templates.find((x) => x.id === items[0].id) ?? null);
      setSelectFormOpen(true);
    }
  };

  const formSelected = (items: Option<string, string>[]) => {
    if (!clientFormId) {
      return;
    }

    // De-duplicate answer
    const newAnswerSeen: Record<string, boolean> = {};
    const deDuplicatedAnswer = [...(response || []), ...items].filter((x) => (newAnswerSeen[x.id] ? false : (newAnswerSeen[x.id] = true)));
    // Now remove any answers that was de-selected in the pick-list
    const answer = deDuplicatedAnswer.filter((x) => x.value !== selectedTemplate?.id || !!items.find((item) => item.id === x.id));

    const newItems = answer.filter((x) => !response?.find((response) => x.id === response.id));
    const removedItems = (response || []).filter((x) => !answer.find((response) => x.id === response.id));

    Promise.all([
      ClientFormService.createReference(
        clientFormId,
        newItems.map((x) => x.id),
        true,
      ),
      ...removedItems.map((x) => ClientFormService.removeReference(clientFormId, x.id)),
    ]).then(() => {
      EventSystem.fireEvent('associations-updated', null);
      onAnswer(answer);
    });
  };

  const createForm = (subtitle: string) => {
    if (!currentClient || !selectedTemplateForm) {
      return;
    }

    return ClientFormService.allocateToClient({
      clientId: currentClient.id,
      subtitle,
      templateFormId: selectedTemplateForm.id,
    })
      .then(async (res) => {
        await fetchForms();
        EventSystem.fireEvent('resource-picklist-updated', { templateForm: selectedTemplateForm.id });
        return res.data.id;
      })
      .catch((err) => {
        toasts.current.addToast({
          title: t('activity-type:resource-pick-list.error-creating'),
          description: err?.data?.meta?.message,
          type: ToastType.ERROR,
        });
      });
  };

  useEffect(() => {
    const handler = (evt: ResourcePicklistUpdatedEvent) => {
      if (evt.templateForm === lockedTemplateId) {
        fetchForms();
      }
    };

    EventSystem.listen('resource-picklist-updated', handler);
    return () => EventSystem.stopListening('resource-picklist-updated', handler);
  }, [fetchForms, lockedTemplateId]);

  const groupedAnswers = useMemo(() => {
    if (!response) {
      return {};
    }

    // Group
    const grouped: Record<string, Option<string, string>[]> = {};
    for (const form of response) {
      if (!grouped[form.value]) {
        grouped[form.value] = [];
      }

      grouped[form.value].push(form);
    }

    // Get Title
    const result: Record<string, Option<string, string>[]> = {};
    for (const [templateId, forms] of Object.entries(grouped)) {
      const title = LanguageUtils.getTranslation('title', templates.find((x) => x.id === templateId)?.translations ?? {});
      result[title] = forms;
    }

    return result;
  }, [response, templates]);

  const templateOptions = useMemo(
    () => templates.map((x) => ({ id: x.id, text: FormUtils.getFormTitle({ form: x }), value: FormUtils.getFormTitle({ form: x }) })),
    [templates],
  );

  const formOptions = useMemo(
    () =>
      forms.map((x) => ({
        id: x.id,
        text: `${x.subtitle || FormUtils.getFormTitle({ subtitle: x.subtitle, form: x })}${FormUtils.formHostName(
          x,
          currentClient?.id || '',
          ' ({client})',
        )}`,
        subText: '',
        value: x.templateId,
      })),
    [currentClient?.id, forms],
  );

  const selectedItems = useMemo(() => response?.map((x) => x.id), [response]);

  const showAddNew = useMemo(
    () => selectedTemplateForm?.type !== FormType.Resource || (selectedTemplateForm?.type === FormType.Resource && canMaintainRecords),
    [canMaintainRecords, selectedTemplateForm?.type],
  );

  const AssociationItem: FC<{ answer: Option<string, string> }> = useMemo(
    () =>
      function associationItem(props) {
        const { answer } = props;
        if (templateLoading) {
          return <></>;
        }
        if (selectedTemplateForm?.type === FormType.Resource) {
          return <CollapsedRecord recordId={answer.id} key={answer.id} templateFormId={selectedTemplateForm?.id} />;
        } else {
          return (
            <CollapsedForm
              formType={CollapsedFormType.AssociatedForm}
              data-cy={`resource-picklist-form-${answer.id}`}
              key={answer.id}
              formId={answer.id}
            />
          );
        }
      },
    [selectedTemplateForm?.id, selectedTemplateForm?.type, templateLoading],
  );

  return (
    <div data-cy="resource-picklist-action">
      <ActionTitleDescription required={required} {...data} />

      <PickListModal
        setupError={setupError}
        title={t('activity-type:resource-pick-list.buttons.select-template')}
        subTitle={question}
        showAddNew={false}
        open={selectTemplateOpen}
        onClose={() => setSelectTemplateOpen(false)}
        single
        onComplete={templateSelected}
        loading={templatesLoading}
        items={templateOptions}
        hideNewEditorDescription
      />
      <PickListModal
        setupError={setupError}
        title={t('activity-type:resource-pick-list.buttons.select-form', {
          name: selectedTemplateForm ? FormUtils.getFormTitle({ form: selectedTemplateForm }) : '',
        })}
        subTitle={question}
        showAddNew={showAddNew && isLinkedToClient}
        open={selectFormOpen}
        onClose={() => setSelectFormOpen(false)}
        onComplete={formSelected}
        loading={formsLoading}
        selectedItems={selectedItems}
        addCustomValue={(translations) => createForm(translations?.[i18n.language]?.name ?? '')}
        items={formOptions}
        single={selectionMode === SelectionMode.Single}
        hideNewEditorDescription
      />
      <div
        className={`relative my-2 ${response && templatesLoading ? 'min-h-12' : ''}`}
        aria-labelledby={`question-title-${currentAction.id}`}
        aria-describedby={`question-description-${currentAction.id}`}
      >
        {response && templatesLoading && <Loader size={8} />}
        {Object.keys(groupedAnswers).length === 1 &&
          Object.values(groupedAnswers)[0].map((answer) => <AssociationItem key={answer.id} answer={answer} />)}
        {Object.keys(groupedAnswers).length > 1 &&
          Object.entries(groupedAnswers).map(([title, answers]) => (
            <Group key={title} title={title}>
              <div className="px-2">
                {answers.map((answer) => (
                  <AssociationItem key={answer.id} answer={answer} />
                ))}
              </div>
            </Group>
          ))}
      </div>
      {currentClient?.id && (
        <button
          data-cy="resource-picklist-open"
          className={`flex-shrink-0 underline ${!readOnly ? 'cursor-pointer' : 'cursor-not-allowed'}`}
          onClick={openTemplatePicker}
        >
          {buttonText || t('activity-type:resource-pick-list.buttons.open-picker')}
        </button>
      )}
      {!currentClient?.id && <div className="my-4 italic">{t('form-builder:action-properties.resource-picklist.preview-not-allowed')}</div>}
    </div>
  );
};

export default ResourcePicklistAction;
